<template>
  <v-container>
    <v-row class="justify-center d-flex">
      <v-col lg="4" md="7" class="mx-auto">
        <v-form @submit.prevent="change_password()">
          <v-card class="card-shadow border-radius-xl">
            <div class="card-padding text-center">
              <h5 class="text-h5 text-typo font-weight-bold">
                Change Password
              </h5>
            </div>

            <div class="card-padding">
              <!-- <v-text-field
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="Password"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                autocomplete="password"
              >
              </v-text-field> -->
              <v-text-field
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="New Password"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
                v-model="new_password_1"
                :append-icon="showPassword_1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword_1 = !showPassword_1"
                :type="showPassword_1 ? 'text' : 'password'"
                autocomplete="new_password_1"
              >
              </v-text-field>
              <v-text-field
                hide-details
                outlined
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="Re-Password"
                class="
                  input-style
                  font-size-input
                  border border-radius-md
                  placeholder-lighter
                  text-color-light
                  mb-4
                "
                v-model="new_password_2"
                :append-icon="showPassword_2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword_2 = !showPassword_2"
                :type="showPassword_2 ? 'text' : 'password'"
                autocomplete="new_password_2"
              >
              </v-text-field>
            </div>
            <!-- <div class="card-padding">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="#5e72e4"
                small
                @click="change_password()"
                >{{ $t("Submit") }}</v-btn
              >
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-40
                "
                color="#5e72e4"
                small
                @click="change_password()"
                >{{ $t("Submit") }}</v-btn
              >
            </div> -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                :ripple="false"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-primary
                  bg-gradient-primary
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                "
                color="#5e72e4"
                @click="reset()"
                >{{ $t("Reset") }}</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                elevation="0"
                :ripple="false"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                "
                color="#5e72e4"
                @click="change_forget_password()"
                >{{ $t("Submit") }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
      :timeout="timeout"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white mr-5">ni ni-bell-55</v-icon>
        <p class="mb-0">
          <span class="font-size-root font-weight-600">Status</span>
          <br />
          {{ snackbar.message }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "forget-password",
  data() {
    return {
      forget_token: null,
      showPassword: false,
      password: null,
      showPassword_1: false,
      new_password_1: "123456",
      showPassword_2: false,
      new_password_2: "123456",
      snackbar: {
        color: null,
        visible: false,
        message: null,
      },
      timeout: 2000,
    };
  },
  mounted() {
    this.forget_token = this.$route.query.token;
    // TODO if no forget token turn to fail
  },
  computed: {},
  methods: {
    ...mapGetters(["getToken"]),
    reset() {
      this.showPassword = false;
      this.password = null;
      this.showPassword_1 = false;
      this.new_password_1 = null;
      this.showPassword_2 = false;
      this.new_password_2 = null;
    },
    change_forget_password() {
      console.log("change forget password");
      if (this.new_password_1 != this.new_password_2) {
        console.log("Not match the password");
        this.SnackbarShow("Info");
        return;
      }

      if (this.forget_token) {
        const currentObj = this;
        let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/forget_password`;

        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.forget_token,
          },
        };
        let data = {
          token: this.forget_token,
          password: this.new_password_1,
        };

        this.axios
          .put(url, data)
          .then(function (response) {
            console.log(response.data);
            // currentObj.onActionDone("Created Completed");
            currentObj.SnackbarShow("Success");
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response.data);
              let detail = error.response.data["detail"];
              if (detail.includes("Incorrect email or password")) {
                currentObj.SnackbarShow("AuthFail");
              }
            } else {
              currentObj.SnackbarShow("Fail");
            }
          })
          .finally(() => {
            this.reset();
          });
      } else {
        console.log("No Token ");
      }
    },
    SnackbarShow(type) {
      if (!type) return;
      switch (type) {
        case "InfoMissing":
          this.snackbar = {
            color: "#17c1e8",
            visible: true,
            message: "資料不完整",
          };
          break;
        case "Info":
          this.snackbar = {
            color: "#17c1e8",
            visible: true,
            message: "密碼不符合",
          };
          break;
        case "Success":
          this.snackbar = {
            color: "#82d616",
            visible: true,
            message: "變更成功",
          };
          break;
        case "AuthFail":
          this.snackbar = {
            color: "#ea0606",
            visible: true,
            message: "密碼錯誤",
          };
          break;
        case "Fail":
          this.snackbar = {
            color: "#ea0606",
            visible: true,
            message: "變更失敗 請聯絡客服",
          };
          break;
      }
    },
  },
};
</script>
